.terminalInputWrapper {
  position: absolute;
  display: flex;
  bottom: 3px;
  left: 0;
  width: 100%;
  color: lime;
  font-family: monospace;
}
.inputForm {
  flex-grow: 0.96;
}

.terminalInput {
  padding: 0;
  border: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);

  color: lime;
  margin-left: 10px;
}
.terminalInput:focus {
  outline: none;
}
