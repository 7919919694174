.linkButton {
  display: flex;
  align-items: center;
  height: 47px;
  font-size: 20px;
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 32px;
  background-color: rgba(17, 17, 17, 0.53);
  backdrop-filter: blur(7px);
  transition: all 375ms ease;
}

.linkButton:hover {
  background-color: rgba(12, 12, 12, 0.85);
  color: lime;
  transition: all 225ms ease;
}

@media (max-width: 1250px) {
  .linkButton {
    height: 32px;
    font-size: 15px;
    padding: 15px 25px;
  }
}
