.tile {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 75px;
  height: 55px;
  background: rgba(0,0,0,0.3);
  font-size: 1.8rem;
  align-items: center;
  color: white;
  transition: all 375ms ease;
}

.tile:hover {
  transition: all 225ms ease;
}

@media screen and (orientation: portrait) {
  .tile {
    width: 33.3%;
    height: 65px;
    font-size: 2.6rem;
  }
}