.name {
  /* color: #f16409; */
  color: #14ec14;
  margin: 10vh auto;
  font-size: 4vw;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 1250px) {
  .name {
    font-size: 5vw;
    text-align: center;
    white-space: unset;
    width: 80%;
  }
}

@media (orientation: portrait) {
  .name {
    margin: 5vh 2% 3vh;
    width: 97%;
    font-size: 6vh;
  }
}

