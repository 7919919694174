.profile {
  position: absolute;
  display: flex;
  align-items: center;
  background: rgba(0,0,0,0.4);
  left: 22px;
  bottom: 22px;
  padding: 4px 22px 0 4px;
  border-radius: 24px;
  backdrop-filter: blur(4px);
}

.avatar {
  border-radius: 50%;
  height: 42px;
}

p {
  color: #ea7127;
  font-size: 22px;
  margin: 0 0 4px 17px;
}

.statusIndicator {
  position: absolute;
  left: 24%;
  bottom: 3%;
  width: 12px;
  height: 12px;
  border: 2px solid black;
  border-radius: 50%;
  background-color: #8c8b8b;
}

.online {
  background-color: #6fdc09;
}

.away {
  background-color: #efd808;
}

.offline {
  background-color: #8c8b8b;
}

@media screen and (max-width: 768px) {
  .profile {
    left: 10px;
    bottom: 15px;
  }
}
