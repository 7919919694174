.socialBarContainer {
  display: flex;
  justify-content: center;

}

.socialBar {
  display: flex;
  background-color: rgba(0,0,0,0.4);
  border-radius: 7px;
  backdrop-filter: blur(4px);
  overflow: hidden;
  flex-wrap: wrap;
}

@media screen and (orientation: portrait) {
  .socialBar {
    margin: 20% 5%;
  }
}