.navWrapper {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.nav {
  display: flex;
  width: 95%;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
