.app {
  width: 100%;
  height: 100vh;
}

pre {
  text-wrap: unset;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
  z-index: -1;
}

.fade-enter {
  opacity: 0.15;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 0.15;
}

.fade-exit-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}
